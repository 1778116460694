<template>
  <div>
    <b-list-group>
      <list-item
        title="Talep Kodu"
        :value="dataItem.rcode"
      />
      <list-item
        title="Talep Eden"
        :value="dataItem.username"
      />
      <list-item
        title="Talebi Oluşturan"
        :value="dataItem.request_user"
      />
      <!--      <list-item-->
      <!--        title="Atanan Yetkili"-->
      <!--        :value="dataItem.administrative_username"-->
      <!--      />-->
      <list-item
        title="Departman"
        :value="dataItem.department"
      />
      <list-item
        title="Talep Konusu"
        :value="dataItem.administrative_request_subject"
      />
      <list-item
        title="Talep Durumu"
        :value="dataItem.administrative_request_status"
      />
      <list-item
        title="Talep Önceliği"
        :value="dataItem.administrative_request_status_code"
      />
      <template v-if="userData.id_com_department !== '23' && userData.id_com_department !== '24'">
        <list-item
          title="Başlangıç Tarihi"
          :value="moment(dataItem.start_date).format('DD.MM.YYYY')"
        />
        <list-item
          title="Bitiş Tarihi"
          :value="moment(dataItem.finish_date).format('DD.MM.YYYY')"
        />
      </template>
    </b-list-group>
  </div>
</template>
<script>

import {
  BListGroup, BListGroupItem, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import ListItem from '@/views/Customers/view/ListItem.vue'
import AdministrativeRequestSDateCard from '@/views/Administrative/Elements/SdateCard.vue'
import AdministrativeRequestEDateCard from '@/views/Administrative/Elements/EdateCard.vue'

export default {
  name: 'AdministrativeRequestInfoCard',
  components: {
    AdministrativeRequestEDateCard, AdministrativeRequestSDateCard, BListGroupItem, BListGroup, ListItem, BRow, BCol, BButton, BSpinner,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      savingDates: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['administrativeRequest/dataItem']
    },
  },
}
</script>
